<template>
  <b-modal id="join-modal" centered :hide-footer="true" size="md">
    <form @submit.prevent="submitForm" class="pb-4 px-3">
      <div class="form-group">
        <label class="d-flex justify-content-between px-2">
          From<span>Balance {{ balance }} ETH</span>
        </label>
        <div class="input-field input-eth">
          <b-form-input type="number" step=".01" id="from" v-model="from" placeholder="0.0"
                        :class="{'is-invalid': $v.from.$invalid && $v.from.$dirty}"/>
          <div class="invalid-feedback" v-if="!$v.from.required && $v.from.$dirty">
            Field is required
          </div>
          <div class="invalid-feedback" v-if="!$v.from.maxValue && $v.from.$dirty">
            Max amount is 7 and not more than balance
          </div>
          <span>ETH</span>
        </div>
      </div>

      <div class="form-group">
        <label class="d-flex justify-content-between px-2">
          To
        </label>
        <div class="input-field input-ctask">
          <b-form-input type="number" id="to" v-model="to" placeholder="to" disabled/>
          <span>CTASK</span>
        </div>
      </div>

      <p class="text-center my-4">Max Allocation is 7 ETH</p>

      <div class="d-flex justify-content-center">
        <button class="btn btn-rounded gradient-color
        d-flex align-items-center justify-content-center px-5" type="submit">
          Join Pool <img src="../assets/img/arrow-right.svg" alt="Join Pool" class="ml-2"/>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, maxValue } from 'vuelidate/lib/validators';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'JoinModal',
  props: {
    balance: {
      type: Number,
      default: 0,
    },
    remaining: {
      type: Number,
      default: 0,
    },
    swapRatio: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      from: null,
      valName: 'maxv',
    };
  },
  validations() {
    return {
      from: {
        required,
        [this.valName]: maxValue(Math.min(7, this.balance)),
      },
    };
  },
  computed: {
    to() {
      return this.from * this.swapRatio;
    },
  },
  methods: {
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // here make logic for submitting form

        // int his way you can return result to parent component
        this.$emit('submit', this.from);

        // close modal
        this.$bvModal.hide('join-modal');
      }
    },
  },
};
</script>

<style scoped>

</style>
