<template>
  <div class="row">
    <div class="col-12 col-md-6 d-flex align-items-center">
      <logo/>
    </div>
    <div class="col-12 col-md-6 d-flex align-items-center justify-content-md-end">
      <eth-display :eth="eth" :ctask="ctask" :hash="hashTrunc"/>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue';
import EthDisplay from '@/components/EthDisplay.vue';

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'HeaderBar',
  components: {
    EthDisplay,
    Logo,
  },
  props: {
    eth: {
      type: Number,
      default: 0,
    },
    ctask: {
      type: Number,
      default: 0,
    },
    hash: {
      type: String,
      default: '',
    },
  },
  computed: {
    hashTrunc() {
      return `${this.hash.substring(0, 5)}....${this.hash.substring(this.hash.length - 4, this.hash.length)}`;
    },
  },
};
</script>
