<template>
  <div class="h-100 d-flex flex-column justify-content-between">
    <div class="container">
      <header-bar class="mb-5 pb-md-5" :eth="ethBalance" :ctask="ctaskBalance" :hash="myAddress"/>
      <div class="row d-flex align-items-center">
        <div class="col-12 col-md-6 pr-md-5 mb-5 mb-md-0">
          <left-box class="pr-md-5" :hash="saleAddress" :etherscanUrl="etherscanUrl"
                    :status="status" :tier="tier"/>
        </div>
        <div class="col-12 col-md-6">
          <right-box :ctask="ratioCtask" :eth="ratioEth" :amount="swapAmount"
                     :progress-total="progressTotal"
                     :progress-current="progressCurrent"
                     :closes-in="closesIn"
                     :tier="tier"
                     :status="status"/>
        </div>

        <join-modal :balance="ethBalance" :swapRatio="ratioCtask" @submit="formSubmitted"/>
      </div>
    </div>
    <div class="note text-center p-3">
      <hr>
      Token transfers are not available until the official Uniswap pool is created post sale.
      By reading this article or analyzing its content, you confirm hereby that you are not a U.S.
      citizen/permanent resident/representing U.S. company or citizen/permanent
      resident/representing Company of any jurisdiction, or other countries such as the
      People’s Republic of China or Japan, where the purchase of CryptoTask (CTASK) tokens
      is illegal, restricted or requires special accreditation.
      If any of the above is the case, you should not read this article, follow the CryptoTask
      project or participate in any activities related to them. Furthermore, you should consult
      your local regulators about digital assets and the risks and benefits of investing
      in them via regulated exchanges.
    </div>
  </div>
</template>

<script>
import LeftBox from '@/components/LeftBox.vue';
import RightBox from '@/components/RightBox.vue';
import JoinModal from '@/components/JoinModal.vue';
import HeaderBar from '@/components/HeaderBar.vue';
import Web3 from 'web3';

const tokenAbi = require('@/assets/erc20.json');
const saleAbi = require('@/assets/sale.json');

export default {
  name: 'Home',
  data() {
    return {
      ratioCtask: 0,
      ratioEth: 1,
      swapAmount: 0,
      progressTotal: 0,
      progressCurrent: 0,
      closesIn: 0,
      ethBalance: 0.0,
      ctaskBalance: 0,
      myAddress: '0x0000000000000000000000000000000000000000',
      tokenAddress: '0x196c81385bc536467433014042788eb707703934',
      saleAddress: '0x802345f487ecd0b0e236b89d89a916b6028a8f01',
      etherscanUrl: 'https://etherscan.io/',
      status: 'pending',
      tier: 1,
    };
  },
  created() {
    this.etherscanUrl = `https://etherscan.io/address/${this.saleAddress}`;
    this.execute();
  },
  components: {
    JoinModal,
    RightBox,
    LeftBox,
    HeaderBar,
  },
  methods: {
    async formSubmitted(event) {
      // event variable has data from modal form submission
      console.log(event);
      await window.web3.eth.sendTransaction(
        {
          from: this.myAddress,
          to: this.saleAddress,
          value: event * 10 ** 18,
        },
      );
      await this.execute();
    },
    async execute() {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        const myAccounts = await window.web3.eth.getAccounts();
        const myAddress = myAccounts[0];
        this.myAddress = myAddress;
        const ethBalance = await window.web3.eth.getBalance(myAddress);
        this.ethBalance = Math.floor((ethBalance / 10 ** 18) * 1000) / 1000;
        console.log(ethBalance);
        const ctask = new window.web3.eth.Contract(tokenAbi, this.tokenAddress);
        const ctaskBalance = await ctask.methods.balanceOf(myAddress)
          .call();
        this.ctaskBalance = Math.floor(ctaskBalance / 10 ** 18);
        console.log(ctaskBalance);
        const sale = new window.web3.eth.Contract(saleAbi, this.saleAddress);
        // eslint-disable-next-line no-underscore-dangle
        let stage = await sale.methods._stage()
          .call();
        stage = parseInt(stage, 10);
        console.log(stage);
        if (stage === 0 || stage === 1) {
          this.tier = 1;
          // eslint-disable-next-line no-underscore-dangle
          let swapRatio = await sale.methods._swapRatio1()
            .call();
          swapRatio = parseInt(swapRatio, 10);
          this.ratioCtask = swapRatio;
          // eslint-disable-next-line no-underscore-dangle
          let cap = await sale.methods._cap1()
            .call();
          cap = parseInt(cap, 10);
          cap = Math.floor(cap / 10 ** 18);
          // eslint-disable-next-line no-underscore-dangle
          let raised = await sale.methods._amountRaisedTier1()
            .call();
          raised = parseInt(raised, 10);
          raised = Math.floor(raised / 10 ** 18);
          this.swapAmount = cap;
          this.progressTotal = cap;
          this.progressCurrent = raised;
          if (stage === 0) {
            // eslint-disable-next-line no-underscore-dangle
            let startTime = await sale.methods._tier1StartTime()
              .call();
            startTime = parseInt(startTime, 10);
            this.closesIn = startTime - Math.floor(Date.now() / 1000);
            this.status = 'pending';
          } else if (stage === 1) {
            // eslint-disable-next-line no-underscore-dangle
            let endTime = await sale.methods._tier1EndTime()
              .call();
            endTime = parseInt(endTime, 10);
            this.closesIn = endTime - Math.floor(Date.now() / 1000);
            this.status = 'live';
          }
        } else if (stage === 2 || stage === 3 || stage === 4) {
          this.tier = 2;
          // eslint-disable-next-line no-underscore-dangle
          let swapRatio = await sale.methods._swapRatio2()
            .call();
          swapRatio = parseInt(swapRatio, 10);
          this.ratioCtask = swapRatio;
          // eslint-disable-next-line no-underscore-dangle
          let cap = await sale.methods._cap2()
            .call();
          cap = parseInt(cap, 10);
          cap = Math.floor(cap / 10 ** 18);
          // eslint-disable-next-line no-underscore-dangle
          let raised = await sale.methods._amountRaisedTier2()
            .call();
          raised = parseInt(raised, 10);
          raised = Math.floor(raised / 10 ** 18);
          this.swapAmount = cap;
          this.progressTotal = cap;
          if (stage === 2) {
            this.progressCurrent = raised;
            // eslint-disable-next-line no-underscore-dangle
            let startTime = await sale.methods._tier2StartTime()
              .call();
            startTime = parseInt(startTime, 10);
            this.closesIn = startTime - Math.floor(Date.now() / 1000);
            this.status = 'pending';
          } else if (stage === 3) {
            this.progressCurrent = raised;
            // eslint-disable-next-line no-underscore-dangle
            let endTime = await sale.methods._tier2EndTime()
              .call();
            endTime = parseInt(endTime, 10);
            this.closesIn = endTime - Math.floor(Date.now() / 1000);
            this.status = 'live';
          } else if (stage === 4) {
            this.status = 'closed';
            // eslint-disable-next-line no-underscore-dangle
            let raised1 = await sale.methods._amountRaisedTier1()
              .call();
            raised1 = parseInt(raised1, 10);
            raised1 = Math.floor(raised1 / 10 ** 18);
            this.progressCurrent = raised + raised1;
          }
        }
      } else {
        // eslint-disable-next-line no-alert
        alert('Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!');
      }
    },
  },
};
</script>
