<template>
  <div>
    <h1 class="mb-5">Crypto Task Public</h1>
    <div class="green-hash left-blue pl-3 py-2 text-success mb-4 hash">
      {{ hash }}
    </div>
    <div class="mb-4">
      <div class="row">
        <div class="col-6">
          <button class="btn btn-rounded gradient-color d-block
            w-100 d-flex align-items-center justify-content-center" @click="openModal"
          :disabled="buttonStatus">
            Join Pool <img src="../assets/img/arrow-right.svg" alt="Join Pool" class="ml-2"/>
          </button>
        </div>
        <div class="col-6">
          <a :href="etherscanUrl" class="btn btn-rounded btn-outline-info d-block w-100 text-white"
             target="_blank">
            View Etherscan
          </a>
        </div>
      </div>
    </div>
    <div class="left-blue d-flex align-items-center pl-3 py-2">
      <span class="tier mr-3">TIER {{ tier }}</span>
      <b-badge pill :variant="statusType">{{ statusString }}</b-badge>
    </div>
  </div>
</template>

<script>
export default {
  name: 'left-box',
  props: {
    hash: {
      type: String,
      default: '',
    },
    etherscanUrl: {
      type: String,
      default: 'https://etherscan.io/',
    },
    tier: {
      type: Number,
      default: 1,
    },
    status: {
      type: String,
      default: 'live',
    },
  },
  computed: {
    statusType() {
      switch (this.status) {
        case 'live':
          return 'success';
        case 'pending':
          return 'warning';
        case 'closed':
          return 'danger';
        default:
          return 'success';
      }
    },
    buttonStatus() {
      switch (this.status) {
        case 'live':
          return false;
        case 'pending':
          return true;
        case 'closed':
          return true;
        default:
          return true;
      }
    },
    /**
     * Capitalize status
     * @return {string}
     */
    statusString() {
      return this.status.charAt(0)
        .toUpperCase() + this.status.slice(1);
    },
  },
  methods: {
    openModal() {
      this.$bvModal.show('join-modal');
    },
  },
};
</script>
