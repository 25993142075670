<template>
  <div class="eth-display d-flex align-items-center">
    <div class="eth gradient-color">{{ eth }} ETH | {{ ctask }} CTASK</div>
    <div class="hash">{{ hash }}</div>
  </div>
</template>

<script>
export default {
  name: 'eth-display',
  props: {
    eth: {
      type: Number,
      default: 0,
    },
    ctask: {
      type: Number,
      default: 0,
    },
    hash: {
      type: String,
      default: '',
    },
  },
};
</script>
