<template>
  <info-box title="Swap Amount">
    <span class="text-success">{{ amountString }} ETH</span>
  </info-box>
</template>
<script>
import InfoBox from '@/components/InfoBox.vue';

export default {
  name: 'swap-amount',
  components: { InfoBox },
  props: {
    amount: {
      type: Number,
      default: 750000,
    },
  },
  computed: {
    amountString() {
      return this.amount.toLocaleString('en').replace(',', ' ');
    },
  },
};
</script>
