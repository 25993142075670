<template>
  <div class="info-box">
    <h4>{{title}}</h4>
    <div><slot/></div>
    <hr v-if="showLine"/>
  </div>
</template>
<script>
export default {
  name: 'info-box',
  props: {
    title: {
      type: String,
      required: true,
    },
    showLine: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
