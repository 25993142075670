<template>
  <info-box title="Swap Progress">
    <b-progress :value="current" :max="total"></b-progress>
    <div class="stats d-flex align-items-center justify-content-between pt-1">
      <span>{{ percent }}%</span>
      <span>{{ current }} / {{ total }}</span>
    </div>
  </info-box>
</template>
<script>
import InfoBox from '@/components/InfoBox.vue';

export default {
  name: 'swap-progress',
  components: { InfoBox },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    percent() {
      return this.current || this.total ? ((this.current / this.total) * 100).toFixed() : 0;
    },
  },
};
</script>
