<template>
  <info-box title="Total Raised">
    <span class="text-success">{{ amountString }} ETH</span>
  </info-box>
</template>

<script>
// noinspection JSUnusedGlobalSymbols
import InfoBox from '@/components/InfoBox.vue';

export default {
  name: 'TotalRaised',
  components: { InfoBox },
  props: {
    amount: {
      type: Number,
      default: 750000,
    },
  },
  computed: {
    amountString() {
      return this.amount.toLocaleString('en').replace(',', ' ');
    },
  },
};
</script>

<style scoped>

</style>
