<template>
  <info-box :title="title" :show-line="false">
    <span class="text-white">{{ closesIn }}</span>
  </info-box>
</template>
<script>
import moment from 'moment';
import InfoBox from '@/components/InfoBox.vue';

require('moment-precise-range-plugin');

export default {
  name: 'closes-in',
  components: { InfoBox },
  data() {
    return {
      t: null,
      closesIn: '',
      interval: null,
    };
  },
  props: {
    time: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: 'live',
    },
  },
  computed: {
    title() {
      switch (this.status) {
        case 'live':
          return 'Closes In';
        case 'pending':
          return 'Starts In';
        case 'closed':
          return 'Sale Closed';
        default:
          return '';
      }
    },
  },
  watch: {
    time(n, o) {
      if (n && n !== o) {
        this.t = this.time;
        this.transform();
      }
    },
  },
  methods: {
    transform() {
      this.closesIn = (this.status === 'closed' || this.t <= 0) ? 0 : moment()
        .preciseDiff(moment()
          .add(this.t, 'seconds'));
    },
  },
  created() {
    this.t = this.time;
    this.transform();

    if (this.status !== 'closed') {
      this.interval = setInterval(() => {
        this.t -= 1;
        this.transform();
      }, 1000);
    }
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>
