<template>
  <info-box title="Swap Ratio">
    <span class="text-success">{{ ctask }} CTASK = {{ eth }} ETH</span>
  </info-box>
</template>
<script>
import InfoBox from '@/components/InfoBox.vue';

export default {
  name: 'swap-ratio',
  components: { InfoBox },
  props: {
    ctask: {
      type: Number,
      default: 0,
    },
    eth: {
      type: Number,
      default: 0,
    },
  },
};
</script>
