<template>
  <div class="right-box d-block w-100 p-4 mb-4 mb-md-0">
    <swap-ratio :ctask="ctask" :eth="eth" v-if="!done"/>
    <swap-amount :amount="amount" v-if="!done"/>
    <swap-progress :total="progressTotal" :current="progressCurrent" v-if="!done"/>
    <closes-in :time="closesIn" :status="status" v-if="!done"/>
    <total-raised :amount="progressCurrent" v-if="done"/>
  </div>
</template>
<script>
import ClosesIn from '@/components/ClosesIn.vue';
import SwapAmount from '@/components/SwapAmount.vue';
import SwapProgress from '@/components/SwapProgress.vue';
import SwapRatio from '@/components/SwapRatio.vue';
import TotalRaised from '@/components/TotalRaised.vue';

export default {
  name: 'right-box',
  props: {
    ctask: {
      type: Number,
      default: 0,
    },
    eth: {
      type: Number,
      default: 0,
    },
    amount: {
      type: Number,
      default: 0,
    },
    progressTotal: {
      type: Number,
      default: 0,
    },
    progressCurrent: {
      type: Number,
      default: 0,
    },
    closesIn: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: 'live',
    },
    tier: {
      type: Number,
      default: 1,
    },
  },
  components: {
    TotalRaised,
    ClosesIn,
    SwapAmount,
    SwapProgress,
    SwapRatio,
  },
  computed: {
    done() {
      return this.tier === 2 && this.status === 'closed';
    },
  },
};
</script>
